import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import background from '../../assets/images/background.jpg'
import { redirect, useNavigate, Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../auth/AuthProvider';
import theme from '../../themeLayout';
import logo from '../../assets/images/Hermes_v1_preto.png'
import { blue, grey, red } from '@mui/material/colors';
import { Paper } from '@mui/material';
import api from '../../services/api';

export default function SignIn() {
  const queryParam = useQuery();
  React.useEffect(()=>{
    if (auth.user!=null)  {
     navigate('/')
    }
  })
  React.useEffect(() => {
    document.title = "IPV7 Tools - Alterar senha"
  }, [])
  const navigate = useNavigate()
  const auth = useAuth()
 
  

  const [errorInput, setErrorInput ] = React.useState({
    email: false,
    password : false,

  })
  const [errorMsg, setErrormsg ] = React.useState({
    severity: "error",
    msg: "",
    show: false
  })


  const handleSubmit = (event) => {
    setErrorInput(obj=>({...obj, username: false, password: false}))
    setErrormsg(errorMsg=>({  ...errorMsg,show:false})) 
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const cred = ({
      email: data.get('email'),
      password: data.get('password'),
      token: queryParam.get("hash")
      
    });


    if(cred.email.length<5){
      setErrorInput(obj=>({...obj, email: true}))
      return;
    }
    if(cred.password.length<8){
      setErrorInput(obj=>({...obj, password: true}))
      return;
    }
    if(data.get('password') != data.get('passwordConfirm')){
      setErrormsg(m=>({show:true, msg: "As senhas não conferem.", severity: 'warning' }))
      return;
    }
    if(!cred.email| !cred.password){
      setErrormsg(m=>({show:true, msg: "Por favor preencha o formulário corretamente.", severity: 'warning' }))
      return;
    }
    api.put("recovery/", cred).then(res=> {

      setErrormsg(m=>({show:true, msg: "Sua senha foi trocada com sucesso!", severity: 'success' }))
      setTimeout(() => {
        navigate('/login')
      }, 5000)
      }).catch(err=>{
        setErrormsg(m=>({show:true, msg: "Não foi possível alterar sua senha.", severity: 'error' }))
        
      })
  };
 return(
  <ThemeProvider theme={theme}>

      <div component="main" 
        style={{
          p:0,
          width: "100%",
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'end',
          justifyContent: 'center',
          height: '100vh',
          backgroundImage: `url(${background})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <CssBaseline />
        <Box minWidth={300} sx={{
          display: 'flex',
          mr: {xs:0, sm: 0, md: 20}
         }}
        
        >

            <Paper elevation={2} component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1,
                maxHeight: 400,
                width: {sm: 500,md:500},
                p:5,
                m:5,
                backgroundColor: 'rgba(242, 242, 242, 1)',
                position: 'relative',
             }}>
              <TextField
                error={errorInput.email}
                margin="normal"
                required
                fullWidth
                name="email"
                minLength="8"
                label="Insira seu email"
                type="email"
                id="email"
                autoComplete="current-email"
                helperText={errorInput.email ? "Verifique este campo.":''}
              />
            <TextField
              error={errorInput.password}
              margin="normal"
              required
              fullWidth
              name="password"
              minLength="8"
              label="Insira sua senha"
              type="password"
              id="password"
              autoComplete="current-password"
              helperText={errorInput.password ? "Verifique este campo. A senha deve ter no mínino 8 caracteres":''}
              />
            <TextField
              error={errorInput.passwordConfirm}
              margin="normal"
              required
              fullWidth
              name="passwordConfirm"
              minLength="8"
              label="Confirme sua senha"
              type="password"
              id="passwordConfirm"
              autoComplete="current-passwordConfirm"
              helperText={errorInput.passwordConfirm ? "Verifique este campo. A senha deve ter no mínino 8 caracteres.":''}
            />
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2,
                backgroundColor: grey[900],

               }}
            >
              Recuperar senha
            </Button>
            <Alert severity={errorMsg.severity}
            sx={{ display: errorMsg.show ? '':'none'  }}
            >{errorMsg.msg}</Alert>
            </Paper>
          </Box>
      </div>
    </ThemeProvider>

  );
}




function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}
