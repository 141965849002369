import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import theme from '../../theme'
import { Alert, Autocomplete, Box, Checkbox, FormControl, FormControlLabel, FormGroup, Grid, IconButton, InputLabel, MenuItem, Select, Snackbar, Tooltip } from '@mui/material';
import api from '../../services/api';
import { render } from 'react-dom';
import { ConstructionOutlined, Delete } from '@mui/icons-material';
import { useAuth } from '../../auth/AuthProvider';




export default function FormDialog(props) {
  const [handlerSnack, setHandlerSnack] = React.useState()

  const item = {
    host_name: '',
    ip_address: '',
    type: "",
    port: '',
    enable_password: '',
    password: '',
    username: '',
    status: true,
  


  }
  const [state, setState] = React.useState(item);
  const auth = useAuth()
  const handleChange = (event) => {
    setState({
      ...state,
      [event.target.name]: event.target.value,
    });
  };

  const handleChangeCheck = (event) => {

    setState({
      ...state,
      [event.target.name]: event.target.checked
    });
  };

  React.useEffect(() => {
    if (!props.open) return
    renderTypes()
    getEditInfo()

  }, [props.id, props.open])
  React.useEffect(() => {
    
    if (state.type > 0 ) {
      renderTypes(state.vendor)
    } else {
      setTypes([])
     
      setState((state) => ({...state, type: "", protocol: ""}))
    }
  }, [state.vendor])


  const getEditInfo = () => {
    setState(item)
    if (!props.id) return
    api.get(`host/${props.id}`)
      .then(res => {
        console.log(res)
        renderTypes(res.data.vendor)
        setState(res.data)
      }).catch(err => setState(item))

  }
  const showNotify = (msg, sev = "success") => {
    setMsgSnack(msg)
    setSevSnack(sev)
    setHandlerSnack(true)
  }

  const [msgSnack, setMsgSnack] = React.useState('')
  const [sevSnack, setSevSnack] = React.useState('')
  const [vendors, setVendors] = React.useState([])
  const [types, setTypes] = React.useState([])
  const [protocols, setProtocols] = React.useState([  {description: "SSH"}, {description: "TELNET"}])



  const renderTypes = () => {
    api.get(`type/`)
      .then(res => {
        setTypes(res.data.results)
       
  
      }).catch(err => {
        showNotify("Um erro ocorreu...", 'error')
        setState((state) => ({...state, type: "", protocol: ""}))
      })
  }

  const handleSubmit = (event) => {
    event.preventDefault()
  
    const handlerClose = () => {
      props.handlerClose()
    }
    if (!props.id) {
      api.post('host/', state)
        .then((res) => {
          if (res.data.error){
            showNotify(res.data.error, 'error')
            return
          }else{

            showNotify("Registro adicionado com sucesso.")
            handlerClose()
          }
        }).catch((err) => {
          // treat error 402
          if (err.response.status == 402) {
            showNotify("Você alcançou o limite contratado pela sua licença.", 'error')
            return
          }
          showNotify("Um erro ocorreu...", 'error')
        })
    } else {
      api.put(`host/${props.id}/`, state)
      .then((res) => {
        if (res.data.error){
          showNotify(res.data.error, 'error')
          return
        }else{

          showNotify("Registro alterado com sucesso.")
          handlerClose()
        }
      }
      ).catch((err) => console.log(err))
    }
  }

  return (
    <div>
      <Snack open={handlerSnack} message={msgSnack} severity={sevSnack} handlerSnack={() => setHandlerSnack(false)} />
      <Dialog open={props.open} fullWidth={true}
        maxWidth='sm'>
        <Box component="form" onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <DialogTitle>
          <Grid item xs={12} spacing={1} md={6} sx={{ display: 'flex', justifyContent: "space-between" }}>
              <Grid item xs={6} md={10} >

                  {!props.id ? 'Criar Host' : "Editar Host"}
              </Grid>
              {props.id ?
          <Grid item xs={6} md={2} >
          <Tooltip title="Apagar Host">
                  <IconButton  disabled={!auth.hasPerm('host.delete')} sx={{ ml:2 }} onClick={() => {
                    const input = window.confirm('Deseja apagar o host?')
                    if (!input) return
                    api.delete(`host/${props.id}`).then(res => {
                      props.handlerClose()
                      showNotify('Host apagado com sucesso')
                    }).catch(err => showNotify('Erro ao apagar backup', 'error'))
                  }
                  }>
                    <Delete />
                  </IconButton>
                </Tooltip>
          </Grid>
          :""}
            </Grid>
          </DialogTitle>
          <DialogContent>

            <TextField
              required
              size="small"
              margin="dense"
              id="host_name"
              name="host_name"
              label="Nome do Host"
              type="text"
              fullWidth
              value={state.host_name}
              onChange={handleChange}
              variant="outlined"
            />

            
            <FormControl fullWidth size="small" sx={{
              mt: 1.3
            }}>
              <InputLabel id="demo-simple-select-label">Tipo</InputLabel>
              <Select
                required
                size="small"
                labelId="type"
                id="type"
                name='type'
                value={state.type}
                label="Marca"
                onChange={handleChange}
              >
                   <MenuItem value="">Selecione uma opção</MenuItem>
                {types ? types.map((type, i) =>
                  <MenuItem key={type.id} value={type.id}>{type.description}</MenuItem>
                ) : ''}
              </Select>

            </FormControl>
            <TextField
              size="small"
              required    
              margin="dense"
              id="ip_address"
              name="ip_address"
              label="IP"
              type="text"
              fullWidth
              value={state.ip_address}
              onChange={handleChange}
              variant="outlined"
            />
            <TextField
              size="small"
              required
              margin="dense"
              id="username"
              name="username"
              label="Usuário"
              type="text"
              fullWidth
              value={state.username}
              onChange={handleChange}
              variant="outlined"
            />

            <TextField
              size="small"
              required
              margin="dense"
              id="password"
              name="password"
              label="Senha"
              type="text"
              fullWidth
              value={state.password}
              onChange={handleChange}
              variant="outlined"
            />
            { state.type == 3 ?
            <TextField
            size="small"
              required      
              margin="dense"
              id="enable_password"
              name="enable_password"
              label="Senha Enable"
              type="text"
              fullWidth
              value={state.enable_password}
              onChange={handleChange}
              variant="outlined"
            />
            : ""}
            <TextField
              size="small"
              margin="dense"
              required
              id="port"
              name="port"
              label="Porta"
              type="number"
              fullWidth
              value={state.port}
              onChange={handleChange}
              variant="outlined"
              />
              <FormGroup>
              <FormControlLabel sx={{ ml: 1, mt: 1 }}
                control={
                  <Checkbox checked={state.status} onChange={handleChangeCheck} name="status"
                  />
                }
                label="Ativo"
              />
            </FormGroup>



          </DialogContent>
          <DialogActions>
            <Button variant='outlined' onClick={props.handlerClose}>Cancelar</Button>
            <Button variant='contained' color="primary" type='submit'> {!props.id ? 'Adicionar' : "Editar"}</Button>
          </DialogActions>
        </Box>
      </Dialog>
    </div>
  );
}

export const Snack = (props) => {
  return (
    <Snackbar
      open={props.open}
      autoHideDuration={6000}
      onClose={() => { props.handlerSnack() }}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
    >
      <Alert onClose={() => { props.handlerSnack() }} severity={props.severity ? props.severity : 'error'} sx={{ width: '100%' }}>
        {props.message}
      </Alert>
    </Snackbar>
  )
}