import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Alert from '@mui/material/Alert'
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Link, useLocation } from 'react-router-dom';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { createTheme, ThemeProvider } from '@mui/material/styles';

import { redirect, useNavigate, Navigate } from 'react-router-dom';
import { useAuth } from '../../auth/AuthProvider';
import theme from '../../themeLayout';
import background from '../../assets/images/background.jpg'

import logo from '../../assets/images/Hermes_v1_preto.png'
import { blue, grey, red } from '@mui/material/colors';
import { Paper } from '@mui/material';


export default function SignIn() {
 
  const navigate = useNavigate()
  const auth = useAuth()
  const location = useLocation()
  React.useEffect(() => {
    document.title = "IPV7 Tools - Login"
  }, [])


  const [errorInput, setErrorInput] = React.useState({
    username: false,
    password: false,
  })
  const [errorMsg, setErrormsg] = React.useState({
    severity: "error",
    msg: "",
    show: false
  })
  const loginApi = (cred) => {
    let from = location.state?.from?.pathname || "/";
    const redirectTo = () => {

      navigate(from, { replace: true })
    }
    const cbErr = () => setErrormsg({
      msg: "Credenciais inválidas.", show: true, severity: 'error'
    })
    auth.signin(cred, redirectTo, cbErr)

  }

  const handleSubmit = (event) => {
    setErrorInput(obj => ({ ...obj, username: false, password: false }))
    setErrormsg(errorMsg => ({ ...errorMsg, show: false }))
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    const cred = ({
      username: data.get('username'),
      password: data.get('password'),
    });
    if (cred.password.length < 8) {
      setErrorInput(obj => ({ ...obj, password: true }))
      return;
    }
    if (cred.username.length < 1) {
      setErrorInput(obj => ({ ...obj, username: true }))
      return;
    }
    if (!cred.username || !cred.password || cred.password.length < 5 || cred.username.length < 1) {
      setErrormsg(m => ({ show: true, msg: "Por favor preencha o formulário corretamente.", severity: 'warning' }))
      return;
    }
    loginApi(cred)
  };

  return (
   <ThemeProvider theme={theme}>

      <div component="main" 
        style={{
          p:0,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'end',
          justifyContent: 'center',
          height: '100vh',
          backgroundImage: `url(${background})`,
          backgroundPosition: 'center',
          backgroundSize: 'cover',
          backgroundRepeat: 'no-repeat'
        }}
      >
        <CssBaseline />
        <Box minWidth={{xs: 100,md:300}} sx={{
          display: 'flex',
          mr: {xs:0, sm: 0, md: 20}
         }}
        
        >

            <Paper elevation={2} component="form" onSubmit={handleSubmit} noValidate sx={{ mt: 1,
                maxHeight: 400,
                width: {sm: 500,md:500},
                p:5,
                m:5,
                backgroundColor: 'rgba(242, 242, 242, 1)',
                
             }}>
              <TextField
                error={errorInput.username}
                margin="normal"
                required
                fullWidth
                id="username"
                label="Usuário"

                name="username"
                autoComplete="username"
                autoFocus
                helperText={errorInput.username ? "Verifique este campo" : ''}
                />
              <TextField
                error={errorInput.password}
                margin="normal"
                required
                fullWidth
                name="password"
                minLength="8"
                label="Senha"
                type="password"
                id="password"
                autoComplete="current-password"
                helperText={errorInput.password ? "Verifique este campo. A senha deve ter no mínino 8 caracteres" : ''}
              />
              <Button
                type="submit"
                fullWidth
                variant="contained"
                sx={{ mt: 3, mb: 2,
                  backgroundColor: grey[900],

                 }}
                 >
                Acessar
              </Button>
              <Link to={"/recovery"} style={{ textDecoration: 'none', color: red[900] }}>
                <Typography variant="body2" color="text.secondary" align="center">
                  Esqueci minha senha
                </Typography>
              </Link>
              <Alert severity={errorMsg.severity}
                sx={{ display: errorMsg.show ? '' : 'none' }}
                >{errorMsg.msg}</Alert>
            </Paper>
          </Box>

      </div>
    </ThemeProvider>

  );
}
